/** @jsx jsx */
import { jsx } from "theme-ui";
// import { graphql } from "gatsby";
import React, { useCallback, useState } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import ReactElasticCarousel from "react-elastic-carousel";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Layout, Content } from "maker-ui";
import banner from "../../static/imgs/dollarAdayUsaBanner.png";
import "../../static/styles/dollar.css";
import options from "../mark-ui/options";
import theme from "../mark-ui/theme";
import HeaderComp from "../components/Header";
import FooterComp from "../components/Footer";
import Seo from "../components/seo";
import shakthi from "../../static/imgs/shakthi.png";
import suraj from "../../static/imgs/suraj.png";
import mahima from "../../static/imgs/mahima.png";
import chirag from "../../static/imgs/chirag.png";
import plantingSaplings from "../../static/imgs/plantingSaplings.png";
import trainingChildren from "../../static/imgs/trainingChildren.png";
import freeworkshop from "../../static/imgs/freeWorkshop.png";
import { eventCallbackTriggers } from "../templates/wppage";
import savings from "../../static/imgs/savings.png";

const impactStories = [
  {
    id: 1,
    title: "Planting Saplings",
    description:
      "Your contribution supports the planting of 10 saplings each month, aiding environmental conservation. These trees absorb carbon dioxide, improve air quality, and provide wildlife habitats. They also beautify urban and rural areas, creating greener, healthier communities. Your support ensures these trees grow and thrive for generations.",
    image: plantingSaplings,
    text: "Forests by Heartfulness",
    color: "#56a51c",
    link: "https://heartfulness.org/forests/",
  },
  {
    id: 2,
    title: "Training Children",
    description:
      "Your donation provides one child with access to the Brighter Minds program, enhancing their cognitive and emotional skills. Thirty dollars a month also supports HELP training for 50 children monthly, fostering an environment where students can excel academically and personally.",
    image: trainingChildren,
    text: "Heartfulness@School",
    color: "#1c76a5",
    link: "https://www.heartfulnessinstitute.org/education/",
  },
  {
    id: 3,
    title: "Free Workshops on Meditation",
    description:
      "Your support enables free workshops teaching meditative practices, promoting mental well-being and stress reduction. These workshops help individuals manage stress, improve focus, and enhance mental health, contributing to a more mindful society.",
    image: freeworkshop,
    text: "Heartfulness@Work",
    color: "#173E5F",
    link: "https://workplace.heartfulnessinstitute.org/",
  },
];

const slides = [
  {
    image: chirag,
    description:
      "“Thirty dollars a month is such a small amount for so many of us, in comparison to all the other subscriptions we have. I like to consider this as my monthly 'inner peace' subscription.”",
    name: "Chirag",
  },
  {
    image: mahima,
    description:
      "“Looking back over the years, I’m overwhelmed with love when I think of the time, guidance, meals, accommodations and friendship the Heartfulness community has given me. This is just one way to share the love.”",
    name: "Mahima",
  },
  {
    image: shakthi,
    description:
      "“Heartfulness helped me tremendously in studying medicine and preparing to become a doctor. I love to pay it forward in whatever small way I can!”",
    name: "Shakti",
  },
  {
    image: suraj,
    description:
      "“Heartfulness has transformed my life. I am so grateful to all the instructors who spent countless hours with me, meditating one-on-one and providing me the guidance I needed to go deeper into my practice. It is an honor to be able to pay it forward!”",
    name: "Suraj",
  },
];

const breakPoints = [
  { width: 300, itemsToShow: 1, pagination: true },
  { width: 550, itemsToShow: 2, pagination: true },
  { width: 600, itemsToShow: 2, pagination: true },
  { width: 800, itemsToShow: 3, pagination: true },
];

const DollarADayRecurring = () => {
  const [amount, setAmount] = useState(30);
  const [customAmount, setCustomAmount] = useState("");
  const [err, setError] = useState("");
  const renderContentHeader = () => (
    <div
      sx={{
        paddingTop: "30px",
        maxHeight: "400px",
        height: "320px",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage: `linear-gradient(rgba(36, 73, 105, 0.5), rgba(36, 73, 105, 0.5)), url(${banner})`,
        color: "white",
        position: "relative",
      }}
    >
      <div
        sx={{
          position: "absolute",
          bottom: "50%",
          right: "60px",
          textAlign: "end",
          color: "#fff",
          transform: "translateY(50%)",
          "@media (max-width: 768px)": {
            right: "50%",
            bottom: "10%",
            transform: "translateX(50%) translateY(10%)",
            width: "100%",
            textAlign: "center",
          },
        }}
      >
        <h1
          sx={{
            fontSize: "3.25rem",
            lineHeight: "2.5rem",
            fontWeight: "bolder",
            letterSpacing: "-2px",
            "@media (max-width: 768px)": {
              fontSize: "2.25rem",
            },
          }}
        >
          <b> Donate $1 a Day</b>
        </h1>
        <h3
          sx={{
            fontSize: "2rem",
            lineHeight: "2.5rem",
            fontWeight: "900",
            "@media (max-width: 768px)": {
              fontSize: "1.5rem",
            },
          }}
        >
          $30 a Month
        </h3>
      </div>
    </div>
  );

  const [currentSlide, setCurrentSlide] = useState(0);
  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("dollar-a-day-usa", event),
    []
  );
  const pageContent = () => {
    let donationAmount = 0;

    if (amount || (customAmount && customAmount > 0)) {
      donationAmount = amount || customAmount;
    }

    return (
      `<gatsby_donation amount="${donationAmount}" donationid="38" btntext="Donate ${
        donationAmount ? `- $${donationAmount}` : ""
      } Monthly"` +
      'projectid="" btnbg="rgb(88 153 208)" colortext="black" colorprimary="#5899D0"' +
      'colorsecondary="#5899D0" colorbackground="white" colormuted="#f6f6f6"' +
      'colorhighlight="#efeffe" titletext="Dollar a day to HI, USA - Recurring" isrecurring="true"/>'
    );
  };
  const PageContent = React.memo(PageContentNonMemoized);
  const nextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };
  const defaultAmounts = [
    { value: 30, perday: 1 },
    { value: 60, perday: 2 },
    { value: 150, perday: 5 },
  ];
  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  return (
    <div className="dollar-wrapper">
      <Layout theme={theme} options={options}>
        <Seo title="Donate $1 a Day | Heartfulness" />
        <HeaderComp headerCustomLogo="/imgs/heartfulness-white-logo.png" />
        <Content>
          {renderContentHeader()}
          <div className="container">
            <div
              className="row my-5"
              sx={{
                display: "flex",
                "@media (max-width: 768px)": {
                  flexDirection: "column-reverse",
                },
              }}
            >
              <div className="col-lg-6 px-3 my-3">
                <h2
                  sx={{
                    color: "#1f4b72",
                    fontWeight: "700",
                    textAlign: "center",
                    fontSize: "2.5rem",
                  }}
                >
                  Our <span sx={{ color: "rgb(88 153 208)" }}>Mission</span>
                </h2>
                <p className="mt-4" sx={{ fontSize: "1rem !important" }}>
                  Heartfulness will always remain free. It is guided by the core
                  principle that divine wisdom cannot be sold. With over 10,000
                  volunteer instructors and a million practitioners worldwide,
                  the Heartfulness Institute runs on the enthusiasm and efforts
                  of each one of us. Whether we share a donation, volunteer our
                  time, or simply stay connected and immersed in the light
                  within—every bit helps contribute to the movement
                </p>
                <p className="mt-4" sx={{ fontSize: "1rem !important" }}>
                  For those who have the means and are looking for a way to give
                  back and pay it forward, a dollar a day is a wonderful place
                  to start!
                </p>
              </div>
              <div className="col-lg-6 my-3">
                <div
                  sx={{
                    border: "1px solid black",
                    borderRadius: "25px",
                    padding: "15px",
                    boxShadow: "0 0 3px rgba(0, 0, 0, 0.2) !important",
                  }}
                >
                  <div
                    sx={{
                      borderBottom: "1px solid black",
                      borderBottomStyle: "dashed",
                      paddingBottom: "20px",
                    }}
                  >
                    <h4
                      className="text-center"
                      sx={{
                        fontSize: "1.85rem",
                        "@media only screen and (max-width: 768px)": {
                          fontSize: "1.5rem !important",
                        },
                      }}
                    >
                      <b sx={{ color: "#1f4b72" }}>Small Contribution -</b>{" "}
                      <b sx={{ color: "rgb(88 153 208)" }}>
                        {" "}
                        Big Transformation
                      </b>
                    </h4>
                  </div>
                  <div className="d-flex justify-content-end align-items-center my-2">
                    <h5
                      className="usd"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      $ USD
                    </h5>
                  </div>
                  {defaultAmounts &&
                    defaultAmounts?.map((a) => (
                      <div className="my-3">
                        <div sx={{ color: "#1f4b72" }}>
                          <b>${a?.perday} per Day</b>
                          <p>${a?.value} donation every month</p>
                        </div>

                        <label
                          htmlFor={a.value}
                          className="radio-container d-flex justify-content-between my-2"
                          sx={{
                            border: "1px solid black",
                            padding: "12px",
                            borderRadius: "10px",
                            color: "#1f4b72",
                            cursor: "pointer",
                            boxShadow: "0 0 3px rgba(0, 0, 0, 0.2) !important",
                          }}
                        >
                          <div>
                            <h4>
                              <b>${a.value}</b>
                            </h4>
                            <span>Frequency Monthly</span>
                          </div>
                          <input
                            type="radio"
                            id={a.value}
                            name="radio-group"
                            value={a.value}
                            checked={amount === a?.value}
                            sx={{
                              width: "30px !important",
                              height: "30px !important",
                            }}
                            onClick={() => {
                              setAmount(a.value);
                              setCustomAmount("");
                              setError("");
                            }}
                          />
                        </label>
                      </div>
                    ))}
                  <div sx={{ color: "#1f4b72" }}>
                    <b>Custom</b>
                    <input
                      type="number"
                      inputMode="numeric"
                      value={customAmount}
                      placeholder="Custom Amount"
                      className="my-3 custom-input"
                      onChange={(event) => {
                        // const pattern = /^\d*[1-9]\d*$/;
                        const pattern = /^[1-9]\d{0,9}$/;
                        if (!pattern.test(event?.target?.value)) {
                          if (
                            !event?.target?.value ||
                            event?.target?.value.length > 10
                          ) {
                            setAmount(30);
                            setError(
                              "Amount must be less than or equal to 1000000000"
                            );
                            setCustomAmount("");
                          } else {
                            setAmount(30);
                            setError("Please enter a valid amount");
                          }
                        } else {
                          setAmount("");
                          setError("");
                          setCustomAmount(event?.target?.value);
                        }
                      }}
                      sx={{
                        width: "100% !important",
                        marginBottom: "10px",
                        fontSize: "14px",
                      }}
                    />
                    {err && (
                      <p sx={{ color: "red", fontSize: "14px" }}>{err}</p>
                    )}
                  </div>
                  <div>
                    <PageContent
                      eventCallback={eventCallback}
                      pageContent={pageContent()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div
              className="my-5 py-5"
              sx={{
                alignCarouselItems: "center",
                justifyContent: "center",
                height: "auto",
                backgroundColor: "#f0f8ff",
                maxWidth: "100% !important",
                width: "100% !important",
              }}
            >
              <div className="mx-2">
                <h2
                  sx={{
                    color: "#1f4b72",
                    fontWeight: "700",
                    textAlign: "start",
                    fontSize: "2.5rem",
                    marginLeft: "2rem",
                    "@media only screen and (max-width: 768px)": {
                      textAlign: "center",
                      marginLeft: "0",
                    },
                  }}
                  className="pb-5"
                >
                  Impact <span sx={{ color: "rgb(88 153 208)" }}>Stories</span>
                </h2>
                <div>
                  <ReactElasticCarousel breakPoints={breakPoints}>
                    {impactStories.map((value) => (
                      <div
                        key={value.id}
                        sx={{
                          height: "250px",
                          color: "#000",
                          outline: "none",
                          // margin: "15px",
                          "@media only screen and (max-width: 400px)": {
                            overflow: "scroll",
                            minHeight: "350px",
                          },
                        }}
                      >
                        <div sx={{ position: "relative" }}>
                          <a
                            href={value?.link}
                            target="_blank"
                            className="links"
                            rel="noreferrer"
                          >
                            <img
                              src={value?.image}
                              className="mb-2 storiesImg"
                              alt="stories"
                            />
                          </a>
                          <div
                            sx={{
                              position: "absolute",
                              backgroundColor: value?.color,
                            }}
                            className="text"
                          >
                            <span>{value?.text}</span>
                          </div>
                        </div>

                        <h6
                          className="my-2 title px-3"
                          sx={{ color: "#1f4b72", fontSize: "1.25rem" }}
                        >
                          <b>{value.title}</b>
                        </h6>
                        <p className="desc  px-3" sx={{ fontSize: "16px" }}>
                          {value.description}
                        </p>
                      </div>
                    ))}
                  </ReactElasticCarousel>
                </div>
              </div>
            </div>
          </div>
          <section className="container">
            <div
              sx={{
                // marginLeft: "5rem",
                "@media (max-width: 768px)": {
                  marginLeft: "0",
                },
              }}
              className="my-5 py-5"
            >
              <div className="row d-flex justify-content-center">
                <div
                  className="col-md-1"
                  sx={{
                    "@media (max-width: 768px)": {
                      display: "none",
                    },
                  }}
                />
                <div className="col-11 col-md-10">
                  <div className="content-bar d-flex align-items-center">
                    <div className="image-container">
                      <img
                        id="image"
                        src={slides?.[currentSlide]?.image}
                        alt="Person"
                        className="img-fluid"
                        sx={{
                          "@media (max-width: 768px)": {
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          },
                        }}
                      />
                    </div>
                    <div className="description-container d-flex flex-grow-1 align-items-center">
                      <div className="py-2">
                        <h1
                          sx={{
                            fontSize: "3rem",
                            fontWeight: "700",
                            paddingTop: "1rem",
                            "@media (max-width: 768px)": {
                              fontSize: "2rem",
                              marginTop: "-70px",
                            },
                          }}
                        >
                          Testimonials
                        </h1>
                        <p
                          id="description"
                          className="text-white mt-3"
                          sx={{
                            fontSize: "1.5rem",
                            "@media (max-width: 768px)": {
                              marginTop: "1rem !important",
                              fontSize: "1rem !important",
                            },
                          }}
                        >
                          {slides?.[currentSlide]?.description}
                        </p>
                        <h5
                          id="name"
                          className="text-white mt-3"
                          sx={{
                            fontSize: "1.5rem",
                            "@media (max-width: 768px)": {
                              fontSize: "1rem !important",
                              marginTop: "2rem !important",
                            },
                          }}
                        >
                          {slides?.[currentSlide]?.name}
                        </h5>
                      </div>
                      <div
                        className="arrow-container"
                        sx={{
                          paddingBottom: "1rem",
                          "@media (max-width: 768px)": {
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          },
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faArrowLeft}
                          size="2x"
                          className={`m-2 ${
                            currentSlide === 0 ? "arrow-btn" : ""
                          }`}
                          sx={{ cursor: "pointer" }}
                          onClick={() => prevSlide()}
                          disabled={currentSlide === 0}
                        />
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          sx={{ cursor: "pointer" }}
                          size="2x"
                          className={`m-2 ${
                            currentSlide === slides.length - 1
                              ? "arrow-btn"
                              : ""
                          }`}
                          onClick={() => nextSlide()}
                          disabled={currentSlide === slides.length - 1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-1" /> */}
              </div>
            </div>
          </section>

          <section className="container">
            <div
              className="row m-5 d-flex"
              sx={{
                backgroundColor: "#5899D0",
                borderRadius: "20px",
                fontSize: "1.25rem",
                fontWeight: "700",
                "@media (max-width: 768px)": {
                  flexDirection: "column-reverse",
                  marginX: "1rem !important",
                  marginY: "3rem !important",
                },
              }}
            >
              <div
                className="col-12 col-md-6 text-white"
                sx={{
                  padding: "3rem 0 3rem 3rem",
                  "@media (max-width: 768px)": {
                    padding: "2rem !important",
                  },
                }}
              >
                <p>
                  Want to make a one-time donation, or a donation outside the
                  United States?
                </p>
                <a
                  href="https://donations.heartfulness.org/"
                  target="_blank"
                  className="clickHere"
                  rel="noreferrer"
                >
                  Click here
                </a>
              </div>
              <div
                className="col-12 col-md-6 savings"
                sx={{
                  textAlign: "center",
                  "@media (max-width: 768px)": {
                    display: "none",
                  },
                }}
              >
                <img
                  id="image"
                  src={savings}
                  alt="savings"
                  className="img-fluid"
                  sx={{
                    height: "100% !important",
                  }}
                />
              </div>
            </div>
          </section>
        </Content>
        <FooterComp />
      </Layout>
    </div>
  );
};

export default DollarADayRecurring;
